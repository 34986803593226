import {
  ThemedButton as Button,
  Stack,
  TextField,
  Typography,
} from '@elphasecure/elpha-design-system'
// import { useStytchB2BClient, useStytchMemberSession } from "@stytch/react/b2b";
import { Controller, useForm } from 'react-hook-form'
// import { Button } from "@mui/material";
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'

export const JoinOrg = () => {
  // const navigate = useNavigate();
  // const stytch = useStytchB2BClient();
  // const { session } = useStytchMemberSession();
  // useEffect(() => {
  //   if (session) {
  //     navigate("/authenticate");
  //   }
  // }, [session, navigate]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      Yup.object({
        orgKey: Yup.string().required('Required'),
      })
    ),
    defaultValues: {
      orgKey: '',
    },
  })

  return (
    <>
      <Stack
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="headlineMedium"
          sx={{
            marginTop: '2rem',
            marginBottom: '1rem',
            color: 'primary.500',
          }}
        >
          Welcome to Elpha Secure
        </Typography>
        <Typography
          variant="bodyMedium"
          sx={{
            marginBottom: '2rem',
            color: 'primary.600',
            textAlign: 'center',
          }}
        >
          Join your organization to continue
        </Typography>
        <form style={{ width: '100%' }} onSubmit={handleSubmit(() => {})}>
          <Stack spacing={2}>
            <Controller
              name="orgKey"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  sx={{
                    minWidth: {
                      xs: '100%',
                      sm: '40rem',
                    },
                  }}
                  label="Your Organization"
                  variant="outlined"
                  error={!!errors.orgKey}
                  helperText={errors.orgKey?.message}
                />
              )}
            />
            <Button
              type="submit"
              sx={{
                minWidth: {
                  xs: '100%',
                  sm: '40rem',
                },
              }}
              variant="contained"
              color="secondary"
            >
              Continue
            </Button>
          </Stack>
        </form>
      </Stack>
    </>
  )
}
