import {
  ThemedButton as Button,
  Stack,
  TextField,
  Typography,
} from '@elphasecure/elpha-design-system'
import { useStytchB2BClient } from '@stytch/react/b2b'
import { useCallback, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'

const OtpForm = () => {
  const navigate = useNavigate()
  const [otp, setOtp] = useState(Array(6).fill(''))
  const inputRefs = useRef<any[]>([])
  const stytch = useStytchB2BClient()
  const [searchParams] = useSearchParams()

  const authenticate = useCallback(() => {
    stytch.otps.sms
      .authenticate({
        organization_id: searchParams.get('organization_id') as string,
        member_id: searchParams.get('member_id') as string,
        code: otp.join(''),
        session_duration_minutes: 960,
      })
      .then((response) => {
        navigate('/authenticate')
      })
      .catch((error) => {
        console.error(
          'Error authenticating user: ',
          JSON.stringify(error, null, 2)
        )
        if (error.error_type === 'otp_code_not_found') {
          toast.error(`The OTP entered seems to be incorrect.`)
        } else {
          toast.error(
            `There was an issue validating your otp. Please contact us if the problem persists.`
          )
          navigate('/logout')
        }
      })
  }, [stytch, otp, searchParams, navigate])

  const handleInputChange = (event, index) => {
    event?.stopPropagation()
    event?.preventDefault()
    let inputValue = event.target.value
    if (inputValue.length > 1) {
      inputValue = inputValue[inputValue.length - 1]
    }
    const inputIndex = otp[0] ? index : 0
    if (/^\d$/.test(inputValue)) {
      setOtp((prevOtp) => {
        const newOtp = [...prevOtp]
        newOtp[inputIndex] = inputValue
        return newOtp
      })
      if (inputValue.length === 1 && index < 5) {
        inputRefs.current[inputIndex + 1].focus()
      }
    }
  }

  const handlePaste = (event) => {
    event?.stopPropagation()
    event?.preventDefault()
    const pastedValue = event.clipboardData.getData('Text')
    if (/^\d{0,6}$/.test(pastedValue)) {
      setOtp([...pastedValue.split('')])
    }
  }

  return (
    <Stack
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography
        variant="headlineMedium"
        sx={{
          marginTop: '2rem',
          marginBottom: '1rem',
          color: 'primary.500',
        }}
      >
        Check your phone
      </Typography>
      <Typography
        variant="bodyMedium"
        sx={{
          marginBottom: '3rem',
          color: 'primary.600',
          textAlign: 'center',
        }}
      >
        Hi! We want to keep your business safe and secure. Please verify
        yourself by entering the 6 digit code we've sent via text.
      </Typography>
      <Stack spacing={2}>
        <Stack
          direction="row"
          spacing={2}
          sx={{
            backgroundColor: '#33335B',
            borderRadius: '1rem',
            padding: '1rem',
            marginBottom: '2rem',
            userSelect: 'none',
          }}
        >
          {Array.from({ length: 6 }, (_, index) => (
            <TextField
              autoFocus={index === 0}
              variant="filled"
              key={index}
              autoComplete="off"
              inputRef={(ref) => (inputRefs.current[index] = ref)}
              value={otp[index] || ''}
              onChange={(event) => handleInputChange(event, index)}
              onPaste={handlePaste}
              onKeyDown={(event) => {
                if (event.key === 'Enter' && otp.every((d) => /^\d$/.test(d))) {
                  authenticate()
                }
                if (event.key === 'Backspace' && otp[index]) {
                  setOtp((prevOtp) => {
                    const newOtp = [...prevOtp]
                    newOtp[index] = ''
                    return newOtp
                  })
                }
                if (
                  event.key === 'Backspace' &&
                  !otp[index] &&
                  otp[index - 1]
                ) {
                  setOtp((prevOtp) => {
                    const newOtp = [...prevOtp]
                    newOtp[index - 1] = ''
                    return newOtp
                  })
                  inputRefs.current[index - 1].focus()
                }
              }}
              placeholder="0"
              sx={{
                '.MuiInputBase-root.MuiFilledInput-root': {
                  color: 'common.white',
                  fontSize: '3rem !important',
                  backgroundColor: 'transparent',
                  caretColor: 'transparent',
                },
                '.MuiInputBase-root.MuiFilledInput-root:hover': {
                  border: 'none',
                },
                '.MuiInputBase-root.MuiFilledInput-root:after': {
                  borderColor: 'secondary.main',
                },
                '.MuiInputBase-root.MuiFilledInput-root:before': {
                  border: 'none',
                },
                '.MuiInputBase-root.MuiFilledInput-root:hover:before': {
                  border: 'none',
                },
              }}
            />
          ))}
        </Stack>
        <Button
          type="submit"
          sx={{
            minWidth: {
              xs: '100%',
              sm: '40rem',
            },
          }}
          variant="contained"
          color="secondary"
          disabled={!otp.every((d) => /^\d$/.test(d))}
          onClick={() => authenticate()}
        >
          Confirm Identity
        </Button>
      </Stack>
    </Stack>
  )
}

export default OtpForm
