import {
  ThemedButton as Button,
  Stack,
  TextField,
  Typography,
} from '@elphasecure/elpha-design-system'
import { useStytchB2BClient } from '@stytch/react/b2b'
import { Controller, useForm } from 'react-hook-form'
// import { Button } from "@mui/material";
import { yupResolver } from '@hookform/resolvers/yup'
import { useLocation, useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { useGetOrgId } from '../../api/useGetOrgId'

export const RequestPasswordReset = () => {
  const { state } = useLocation()
  const navigate = useNavigate()
  const stytch = useStytchB2BClient()
  const { mutateAsync: getOrgId } = useGetOrgId()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      Yup.object({
        email: Yup.string().email('Invalid email address').required('Required'),
      })
    ),
    defaultValues: {
      email: state?.email || '',
    },
  })

  const resetPassword = async (data: any) => {
    const orgId = await getOrgId({ email: data.email })
    try {
      await stytch.passwords.resetByEmailStart({
        email_address: data.email,
        organization_id: orgId.org_id,
        reset_password_template_id: 'password_reset_by_user',
      })

      navigate('/check-email', {
        state: {
          email: data.email,
        },
      })
    } catch (error) {
      console.error(
        'Error resetting password: ',
        JSON.stringify(error, null, 2)
      )
      toast.error(
        `Uh oh. There was an issue sending reset link. Please contact us if the problem persists.`
      )
    }
  }

  return (
    <Stack
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography
        variant="headlineMedium"
        sx={{
          marginTop: '2rem',
          marginBottom: '1rem',
          color: 'primary.500',
        }}
      >
        Forgot Password
      </Typography>
      <Typography
        variant="bodyMedium"
        sx={{
          marginBottom: '2rem',
          color: 'primary.600',
          textAlign: 'center',
        }}
      >
        An email will be sent with a reset password link.
      </Typography>
      <form style={{ width: '100%' }} onSubmit={handleSubmit(resetPassword)}>
        <Stack spacing={2}>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                data-dd-privacy="allow"
                sx={{
                  minWidth: {
                    xs: '100%',
                    sm: '40rem',
                  },
                }}
                label="Email"
                variant="outlined"
                error={!!errors.email}
                helperText={errors.email?.message as string}
              />
            )}
          />
          <Button
            type="submit"
            sx={{
              minWidth: {
                xs: '100%',
                sm: '40rem',
              },
            }}
            variant="contained"
            color="secondary"
          >
            Send Reset Link
          </Button>
        </Stack>
      </form>
    </Stack>
  )
}
