const palette = {
  primary: {
    main: '#5029ef',
    200: '#eaf0fe',
    300: '#dfe5f3',
    400: '#6f6e89',
    500: '#250f7f',
    600: '#191933',
    700: '#f7f9ff',
  },
  secondary: {
    main: '#09f5a2',
  },
  common: {
    300: '#292954',
    400: '#6e65a0',
    500: '#ffba00',
    white: '#ffffff',
    black: '#000000',
  },
  success: {
    main: '#09f5a2',
  },
  info: {
    main: '#20cbfe',
  },
  alert: {
    main: '#ffbc17',
  },
  warning: {
    main: '#f17b25',
  },
  error: {
    main: '#f14949',
  },
  text: {
    primary: '#191933',
  },
  severity: {
    informational: '#09F5A2',
    low: '#20CBFE',
    elevated: '#FFBC17',
    high: '#F17B25',
    critical: '#F14949',
  },
}

export default palette
