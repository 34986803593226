import { Stack, Typography } from '@elphasecure/elpha-design-system'
import { useStytchB2BClient } from '@stytch/react/b2b'
import { useEffect } from 'react'
// import { Button } from "@mui/material";
import { useNavigate } from 'react-router'

export const Logout = () => {
  const navigate = useNavigate()
  const stytch = useStytchB2BClient()

  useEffect(() => {
    stytch.session
      .revoke()
      .finally(() => {
        console.log('navigating to login')

        // wait for the current JS stack to be executed and add navigate at the end of stack.
        // Observation: Stytch promise is resolved before clearing cookies and removing session from storage.
        // This only occurs some times and possible race condition
        setTimeout(() => navigate('/login'))
      })
      .catch((error) => {
        console.error(error)
      })
  }, [stytch, navigate])

  return (
    <>
      <Stack
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="headlineMedium"
          sx={{
            marginY: '1rem',
          }}
        >
          Logging Out...
        </Typography>
      </Stack>
    </>
  )
}
