import {
  ThemedButton as Button,
  Divider,
  Stack,
  TextField,
  Typography,
} from '@elphasecure/elpha-design-system'
import { useStytchB2BClient, useStytchMemberSession } from '@stytch/react/b2b'
import { Controller, useForm } from 'react-hook-form'
// import { Button } from "@mui/material";
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import * as Yup from 'yup'

export const Login = () => {
  const { state } = useLocation()
  const navigate = useNavigate()
  const stytch = useStytchB2BClient()
  const { session } = useStytchMemberSession()
  useEffect(() => {
    if (session) {
      navigate('/authenticate')
    }
  }, [session, navigate])

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      Yup.object({
        email: Yup.string().email('Invalid email address').required('Required'),
      })
    ),
    defaultValues: {
      email: state?.email || '',
    },
  })

  const sendDiscoveryEmail = (data: any) => {
    stytch.magicLinks.email.discovery
      .send({
        email_address: data.email,
        login_template_id: 'passwordless',
        discovery_redirect_url: window.location.href.replace(
          '/login',
          '/authenticate'
        ),
        /**
         * discovery_redirect_url=
         * The URL that the end user clicks from the discovery Magic Link.
         * This URL should be an endpoint in the backend server that
         * verifies the request by querying Stytch's discovery authenticate
         * endpoint and continues the flow. If this value is not passed,
         * the default discovery redirect URL that you set in your Dashboard
         * is used. If you have not set a default discovery redirect URL,
         * an error is returned.
         */
      })
      .then(() => {
        navigate('/check-email', {
          state: {
            email: data.email,
            title: 'Password-Free Login',
            description:
              'Now you can login without a password! An email has been sent to ',
          },
        })
      })
      .catch((error) => {
        console.error(
          'Error sending discovery email: ',
          JSON.stringify(error, null, 2)
        )
        toast.error(
          `There was an issue sending the one-time link to your email. Please contact us if the problem persists.`
        )
      })
  }

  return (
    <Stack
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography
        variant="headlineMedium"
        sx={{
          marginTop: '2rem',
          marginBottom: '1rem',
          color: 'primary.500',
        }}
      >
        Welcome to Elpha Secure
      </Typography>
      <Typography
        variant="bodyMedium"
        sx={{
          marginBottom: '2rem',
          color: 'primary.600',
          textAlign: 'center',
        }}
      >
        Login with your information.
      </Typography>
      <form
        style={{ width: '100%' }}
        onSubmit={handleSubmit(sendDiscoveryEmail)}
      >
        <Stack spacing={2}>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                data-dd-privacy="allow"
                {...field}
                sx={{
                  minWidth: {
                    xs: '100%',
                    sm: '40rem',
                  },
                }}
                label="Email"
                variant="outlined"
                error={!!errors.email}
                helperText={errors.email?.message as string}
              />
            )}
          />
          <Button
            type="submit"
            sx={{
              minWidth: {
                xs: '100%',
                sm: '40rem',
              },
            }}
            variant="contained"
            color="secondary"
          >
            Continue with email
          </Button>
        </Stack>
      </form>
      <br />
      <Divider flexItem>OR </Divider>

      <Typography
        variant="bodyMedium"
        sx={{
          marginY: '1rem',
          cursor: 'pointer',
          color: 'primary.main',
        }}
        onClick={() =>
          navigate('/login-password', {
            state: {
              email: getValues().email,
            },
          })
        }
      >
        Login using Password
      </Typography>
    </Stack>
  )
}
