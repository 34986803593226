import { useState, useEffect } from 'react'
import { B2BAuthenticateResponseWithMFA } from '@stytch/vanilla-js'
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom'
import { useStytchB2BClient } from '@stytch/react/b2b'

const useUserNavigationMFA = () => {
  const [authResponse, setAuthResponse] =
    useState<B2BAuthenticateResponseWithMFA | null>(null)
  const stytch = useStytchB2BClient()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (!authResponse) return

    if (!authResponse.mfa_required) {
      if (location.pathname !== '/authenticate') navigate('/authenticate')
    } else if (authResponse.mfa_required.secondary_auth_initiated) {
      navigate({
        pathname: '/otp',
        search: createSearchParams({
          organization_id: authResponse.organization.organization_id,
          member_id: authResponse.member_id,
        }).toString(),
      })
    } else if (authResponse.mfa_required.member_options?.mfa_phone_number) {
      stytch.otps.sms
        .send({
          organization_id: authResponse.organization.organization_id,
          member_id: authResponse.member_id,
        })
        .then(() => {
          navigate({
            pathname: '/otp',
            search: createSearchParams({
              organization_id: authResponse.organization.organization_id,
              member_id: authResponse.member_id,
            }).toString(),
          })
        })
    } else {
      navigate({
        pathname: '/phone-number',
        search: createSearchParams({
          organization_id: authResponse.organization.organization_id,
          member_id: authResponse.member_id,
        }).toString(),
      })
    }
    // eslint-disable-next-line
  }, [authResponse])

  return [setAuthResponse]
}

export default useUserNavigationMFA
