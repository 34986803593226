import {
  Box,
  ThemedButton as Button,
  Divider,
  Stack,
  TextField,
  Typography,
} from '@elphasecure/elpha-design-system'
import { yupResolver } from '@hookform/resolvers/yup'
import { useStytchB2BClient } from '@stytch/react/b2b'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import useUserNavigationMFA from '../../hooks/useUserNavigationMFA'

export const ResetPassword = () => {
  const stytch = useStytchB2BClient()
  let [error, setError] = useState<string>('')
  let [suggestions, setSuggestions] = useState<string[]>([])
  let navigate = useNavigate()
  const [setAuthResponse] = useUserNavigationMFA()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      Yup.object({
        password: Yup.string().required('Required'),
        confirmPassword: Yup.string().required('Required'),
      })
    ),
    defaultValues: {
      confirmPassword: '',
      password: '',
    },
  })

  useEffect(() => {
    if (stytch.session.getTokens()) {
      stytch.session
        .revoke()
        .finally(() => {
          console.error('revoked session')
        })
        .catch((error) => {
          console.error(error)
        })
    }
    // eslint-disable-next-line
  }, [])

  const resetPassword = (data) => {
    setSuggestions([])
    if (data.password !== data.confirmPassword) {
      setError('Passwords do not match')
      return
    }
    setError('')
    const currentLocation = new URL(window.location.href)
    const token = currentLocation.searchParams.get('token') as string

    stytch.passwords
      .strengthCheck({ password: data.password })
      .then((strengthCheck) => {
        if (strengthCheck.valid_password) {
          return stytch.passwords.resetByEmail({
            password_reset_token: token,
            password: data.password,
            session_duration_minutes: 960,
          })
        } else {
          if (strengthCheck.strength_policy === 'luds') {
            setError(
              `Follow at least ${
                strengthCheck.luds_feedback.missing_complexity +
                (strengthCheck.luds_feedback.missing_characters ? 1 : 0)
              } suggestions.`
            )
            const suggestions: string[] = []
            if (strengthCheck.luds_feedback.missing_characters) {
              suggestions.push(
                `Password should be at least ${
                  strengthCheck.luds_feedback.missing_characters +
                  data.password.length
                } characters long (mandatory)`
              )
            }
            if (!strengthCheck.luds_feedback.has_digit) {
              suggestions.push('Add at least 1 number')
            }
            if (!strengthCheck.luds_feedback.has_lower_case) {
              suggestions.push('Add at least 1 lowercase character')
            }
            if (!strengthCheck.luds_feedback.has_upper_case) {
              suggestions.push('Add at least 1 uppercase character')
            }
            if (!strengthCheck.luds_feedback.has_symbol) {
              suggestions.push('Add at least 1 special character')
            }
            setSuggestions(suggestions)
          } else {
            setError(strengthCheck.zxcvbn_feedback.warning)
            setSuggestions(strengthCheck.zxcvbn_feedback.suggestions)
          }
          return null
        }
      })
      .then((authResponse) => {
        if (
          authResponse?.organization?.trusted_metadata?.active_status === false
        ) {
          throw new Error('Your account is not active. Please contact us.')
        }

        setAuthResponse(authResponse)
      })
      .catch((error) => {
        if (error?.error_message || error?.message) {
          console.error(
            'Error resetting password: ',
            JSON.stringify(error, null, 2)
          )
          toast.error(error.error_message || error.message)
          navigate('/logout')
          return
        }
        if (
          error.error_type === 'unable_to_auth_password_reset_token' ||
          error.error_type === 'unable_to_auth_magic_link'
        ) {
          setError('Token expired. Please request for password reset again.')
          return
        }
        console.error(
          'Error resetting password: ',
          JSON.stringify(error, null, 2)
        )
        setError(error.error_message)
      })
  }

  return (
    <Stack
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography
        variant="headlineMedium"
        sx={{
          marginY: '1rem',
          color: 'primary.500',
        }}
      >
        Reset Password
      </Typography>
      <form style={{ width: '100%' }} onSubmit={handleSubmit(resetPassword)}>
        <Stack spacing={2}>
          <Controller
            name="password"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                sx={{
                  marginY: '1rem',
                  minWidth: {
                    xs: '100%',
                    sm: '40rem',
                  },
                }}
                label="Password"
                type="password"
                variant="outlined"
                error={!!errors.password}
                helperText={errors.password?.message as string}
              />
            )}
          />
          <Controller
            name="confirmPassword"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                sx={{
                  marginY: '1rem',
                  minWidth: {
                    xs: '100%',
                    sm: '40rem',
                  },
                }}
                label="Confirm New Password"
                variant="outlined"
                type="password"
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword?.message}
              />
            )}
          />
          {error && (
            <Box
              sx={{
                borderRadius: '1rem',
                borderWidth: '0.25rem',
                borderLeftWidth: '1rem',
                borderStyle: 'solid',
                padding: '2rem',
                borderColor: 'error.main',
              }}
            >
              <Typography
                variant="bodyLarge"
                sx={{
                  fontWeight: '700',
                  marginBottom: suggestions?.length ? '1rem' : '0',
                }}
                component={'div'}
              >
                {error}
              </Typography>
              {suggestions?.length ? (
                <Typography variant="bodySmall" sx={{ color: 'primary.400' }}>
                  Here's some suggestions to make your password stronger:
                  <ul
                    style={{
                      margin: 0,
                    }}
                  >
                    {suggestions.map((s) => (
                      <li>{s}</li>
                    ))}
                  </ul>
                </Typography>
              ) : (
                <></>
              )}
            </Box>
          )}
          <Button
            type="submit"
            sx={{
              marginY: '1rem',
              minWidth: {
                xs: '100%',
                sm: '40rem',
              },
            }}
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
        </Stack>
      </form>
      <br />
      <Divider flexItem>OR </Divider>

      <Typography
        variant="bodyMedium"
        sx={{
          marginY: '1rem',
          cursor: 'pointer',
          color: 'primary.main',
        }}
        onClick={() => navigate('/login')}
      >
        Login using one-time link
      </Typography>
    </Stack>
  )
}
