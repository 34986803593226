import {
  Breakpoints,
  createTheme,
  PaletteOptions,
  Shadows,
} from '@mui/material/styles'
import palette from './palette'
import typography from './typography'
import breakpoints from './breakpoints'

import './../types/material'

const globalTheme = createTheme({
  breakpoints: breakpoints as Breakpoints,
  palette: palette as PaletteOptions,
  typography,
  shape: {
    borderRadius: 8,
  },
  shadows: [
    'none',
    '0px 12px 50px rgba(80, 41, 239, 0.04)',
    '0px 12px 30px rgba(37, 15, 127, 0.08)',
    'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 12px 24px 0px',
    '0px 12px 50px rgba(80, 41, 239, 0.04)',
    ...Array(20).fill('none'),
  ] as Shadows,
})

const theme = createTheme({
  ...globalTheme,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: globalTheme.palette.primary[700],
        },
        html: {
          fontSize: '10px',
          scrollBehavior: 'smooth',
        },
      },
    },
    MuiPagination: {
      styleOverrides: {},
    },

    MuiPaginationItem: {
      styleOverrides: {
        root: {
          fontSize: '16px',
          color: globalTheme.palette.primary[400],
          '&.Mui-selected': {
            backgroundColor: globalTheme.palette.secondary.main,
            color: globalTheme.palette.primary[600],
            '&:hover': {
              backgroundColor: globalTheme.palette.secondary.main,
              color: globalTheme.palette.primary[600],
            },
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        popper: {
          marginTop: '5px !important',
          marginBottom: '5px !important',
        },
        listbox: {
          '& .MuiAutocomplete-option[aria-selected="true"]': {
            backgroundColor: globalTheme.palette.primary[200],
          },
          '& .MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
            backgroundColor: globalTheme.palette.primary[200],
          },
          '& .MuiAutocomplete-option.Mui-selected': {
            backgroundColor: globalTheme.palette.primary[200],
          },
          '& .MuiAutocomplete-option.Mui-selected.Mui-focused': {
            backgroundColor: globalTheme.palette.primary[200],
          },
        },
        option: {
          marginLeft: '12px',
          marginRight: '12px',
          marginBottom: '4px',
          borderRadius: '12px',
          '&:hover': {
            backgroundColor: globalTheme.palette.primary[200],
          },
          '&.Mui-focused ': {
            backgroundColor: globalTheme.palette.primary[200] + '!important',
          },
        },
        input: {
          padding: '1.2rem 0rem !important',
        },
        inputRoot: {
          padding: 0,
          paddingLeft: '2rem',
        },
        paper: {
          borderWidth: '2px',
          borderStyle: 'solid',
          borderColor: globalTheme.palette.primary.main,
        },
        tag: {
          backgroundColor: globalTheme.palette.primary[200],
          height: 26,
          position: 'relative',
          zIndex: 0,
          '& .MuiChip-label': {
            color: globalTheme.palette.primary[600],
          },
          '& .MuiChip-deleteIcon': {
            color: globalTheme.palette.primary[200],
            '&:hover': {
              color: globalTheme.palette.primary[200],
            },
          },
          '&:after': {
            content: '""',
            right: 10,
            top: 6,
            height: 12,
            width: 12,
            position: 'absolute',
            backgroundColor: '#200E32',
            opacity: 0.4,
            zIndex: -1,
          },
        },
      },
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          marginTop: '0.4rem',
          borderWidth: '2px',
          borderStyle: 'solid',
          borderColor: globalTheme.palette.primary[200],
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          alignItems: 'start',
        },

        label: {
          paddingTop: '0.3rem',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          padding: '1.2rem 2rem !important',
          height: '2.2rem',
        },
        inputAdornedStart: {
          paddingLeft: '0rem !important',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          transform: 'translate(20px, 12px) scale(1)',
        },
        shrink: {
          transform: 'translate(20px, -9px) scale(0.75)',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&:hover fieldset': {
            borderColor: `${globalTheme.palette.primary.main} !important`,
          },
          '&.Mui-focused fieldset': {
            borderColor: `${globalTheme.palette.primary.main} !important`,
          },
          '&.Mui-error fieldset': {
            borderColor: `${globalTheme.palette.error.main} !important`,
          },
        },
        notchedOutline: {
          padding: '0px 13px',
          borderWidth: '0.2rem',
          borderColor: globalTheme.palette.primary[200],
        },
      },
    },

    MuiDialog: {
      defaultProps: {
        BackdropProps: {
          style: {
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
            backdropFilter: 'blur(40px)',
            WebkitBackdropFilter: 'blur(20px)',
          },
        },
      },
      styleOverrides: {
        paper: {
          borderRadius: '2.0rem',
        },
        paperWidthXs: {
          maxWidth: '530px',
        },
        paperWidthSm: {
          maxWidth: '760px',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          justifyContent: 'flex-start',
          paddingTop: '0rem',
          paddingLeft: '4.0rem',
          paddingRight: '4.0rem',
          paddingBottom: '4.0rem',
        },
      },
    },
    MuiDialogContent: {
      defaultProps: {
        dividers: true,
      },
      styleOverrides: {
        root: {
          padding: '4.0rem',
        },
        dividers: {
          borderColor: globalTheme.palette.common.white,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: globalTheme.palette.primary[200],
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          '&:hover': {
            backgroundColor: globalTheme.palette.primary[200],
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
          cursor: 'pointer',
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'currentcolor',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: '0.2rem !important',
        },
      },
    },
    MuiPaper: {
      variants: [
        {
          props: { variant: 'hoverable' },
          style: {
            boxShadow: '0px 12px 50px rgba(80, 41, 239, 0.04)',
            '&:hover': {
              boxShadow: '0px 12px 30px rgba(37, 15, 127, 0.08)',
            },
          },
        },
      ],
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          '&.Mui-selected:hover': {
            backgroundColor: globalTheme.palette.primary.main,
          },
          '&.Mui-selected:focus': {
            backgroundColor: globalTheme.palette.primary.main,
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: 0,
          marginTop: '1.2rem',
          color: globalTheme.palette.primary[300],
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          height: 12,
        },
        thumb: {
          height: 23,
          width: 23,
          backgroundColor: globalTheme.palette.common.white,
          border: '8px solid currentColor',
          '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
          },
          '&:before': {
            display: 'none',
          },
        },
        rail: {
          opacity: 1,
          backgroundColor: globalTheme.palette.primary[300],
        },
        mark: {
          backgroundColor: globalTheme.palette.common.white,
          borderRadius: 999,
          height: 10,
          width: 10,
          '&.MuiSlider-markActive': {
            backgroundColor: 'currentColor',
          },
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        alternativeLabel: {
          top: 22,
          left: 'calc(-50% + 44px)',
          right: 'calc(50% + 44px)',
        },
        line: {
          borderTopWidth: 1,
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          height: '4.4rem',
          width: '4.4rem',
        },
      },
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '2rem',
          paddingLeft: '2.8rem',
          paddingRight: '2.8rem',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        sizeMedium: {
          border: 0,
          padding: '2.8rem',
          '&.MuiTableCell-head': {
            paddingBottom: '0.4rem !important',
          },
        },
        head: {
          paddingTop: '0rem !important',
          paddingBottom: '1.2rem !important',
          fontSize: '1.4rem',
          fontWeight: 'normal',
          color: globalTheme.palette.primary[400],
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          background: globalTheme.palette.common.white,
          '&.MuiTableRow-hover': {
            boxShadow: '0px 12px 50px rgba(80, 41, 239, 0.04)',
            '&:hover': {
              backgroundColor: globalTheme.palette.common.white,
              boxShadow: '0px 12px 30px rgba(37, 15, 127, 0.08)',
            },
          },
        },
        head: {
          background: 'transparent',
          boxShadow: 'none',
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
      styleOverrides: {
        tooltip: {
          fontSize: '1.2rem',
          // borderRadius: "0.8rem",
          backgroundColor: globalTheme.palette.primary[600],
          // padding: "0.4rem 1.2rem",
        },
        arrow: {
          color: globalTheme.palette.primary[600],
        },
        // tooltipPlacementBottom: {
        //   marginTop: "0.8rem !important",
        // },
        // tooltipPlacementTop: {
        //   marginTop: "0.8rem !important",
        // },
      },
    },
  },
})

export default theme
