import axios from 'axios'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
// if undefined, then base url will be empty string, Axios will use the current domain
const baseUrl = `${
  (window as any)?._env_?.REACT_APP_AUTH_SVC !== undefined
    ? (window as any)?._env_?.REACT_APP_AUTH_SVC
    : process?.env?.REACT_APP_AUTH_SVC
}`
const elphaToken = `${
  (window as any)?._env_?.REACT_APP_ORG_SEARCH_TOKEN !== undefined
    ? (window as any)?._env_?.REACT_APP_ORG_SEARCH_TOKEN
    : process?.env?.REACT_APP_ORG_SEARCH_TOKEN
}`

const useGetOrgId = () => {
  return useMutation(
    (req: { email: string }) =>
      axios
        .post(`${baseUrl}/public/orgid`, req, {
          headers: {
            'x-es-api-key': elphaToken,
          },
        })
        .then((res) => res.data),
    {
      onError: (error) => {
        console.error(
          'Error getting organization info for user: ',
          JSON.stringify(error, null, 2)
        )
        toast.error(
          `Uh oh. There was an issue gathering organization info for the user. Please contact us if the problem persists.`
        )
      },
    }
  )
}

export { useGetOrgId }
