import axios from "axios";
import { useQuery } from "react-query";
// if undefined, then base url will be empty string, Axios will use the current domain
const baseUrl = `${
  (window as any)?._env_?.REACT_APP_AUTH_SVC !== undefined
    ? (window as any)?._env_?.REACT_APP_AUTH_SVC
    : process?.env?.REACT_APP_AUTH_SVC
}`
const env = `${process?.env?.REACT_APP_ENV || ""}`;

const useGetEnvKey = (envKey: string) => {
  return useQuery(
    ["getEnv", envKey],
    () => {
      return axios.get(`${baseUrl}/public/env`, { params: { key: envKey } });
    },
    { enabled: !!envKey && env !== "local", staleTime: 300000 }
  );
};

export { useGetEnvKey };
