import {
  ThemedButton as Button,
  Stack,
  TextField,
  Typography,
} from '@elphasecure/elpha-design-system'
import { useStytchB2BClient } from '@stytch/react/b2b'
import { Controller, useForm } from 'react-hook-form'
// import { Button } from "@mui/material";
import { yupResolver } from '@hookform/resolvers/yup'
import { B2BAuthenticateResponseWithMFA } from '@stytch/vanilla-js/b2b'
import { useLocation, useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { useGetOrgId } from '../../api/useGetOrgId'
import useUserNavigationMFA from '../../hooks/useUserNavigationMFA'

export const LoginPassword = () => {
  const { state } = useLocation()
  let navigate = useNavigate()
  const stytch = useStytchB2BClient()
  const { mutateAsync: getOrgId } = useGetOrgId()
  const [setAuthResponse] = useUserNavigationMFA()
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      Yup.object({
        email: Yup.string().email('Invalid email address').required('Required'),
        password: Yup.string().required('Required'),
      })
    ),
    defaultValues: {
      email: state?.email || '',
      password: '',
    },
  })

  const login = async (data: any) => {
    const orgId = await getOrgId({ email: data.email })
    try {
      let authResponse: B2BAuthenticateResponseWithMFA
      authResponse = await stytch.passwords.authenticate({
        email_address: data.email,
        password: data.password,
        organization_id: orgId.org_id,
        session_duration_minutes: 960,
      })

      if (authResponse.organization.trusted_metadata.active_status === false) {
        throw new Error('Your account is not active. Please contact us.')
      }

      setAuthResponse(authResponse)
    } catch (error: any) {
      if (error?.error_message || error?.message) {
        console.error('Error logging in user: ', JSON.stringify(error, null, 2))
        toast.error(error.error_message || error.message)
        navigate('/logout')
        return
      }
      if (
        error.error_type === 'member_password_not_found' ||
        error.error_type === 'member_reset_password' ||
        error.error_type === 'weak_password'
      ) {
        stytch.passwords
          .resetByEmailStart({
            email_address: data.email,
            organization_id: orgId.org_id,
            reset_password_template_id: 'password_reset',
          })
          .then(() => {
            toast.error(
              `You are required to reset your password to login. An email is sent with reset password link. `,
              {
                onClose: () => {
                  navigate('/check-email', {
                    state: {
                      email: data.email,
                    },
                  })
                },
              }
            )
          })
          .catch(() => {
            toast.error(
              `You are required to reset your password to login. Redirecting to password reset page.`,
              { onClose: resetPassword }
            )
          })
        return
      }
      console.error('Error logging in user: ', JSON.stringify(error, null, 2))
      toast.error(
        `Uh oh. There was an issue logging in the user. Please check the email and password again. Contact us if the problem persists.`
      )
    }
  }
  const resetPassword = () => {
    navigate('/request-password', {
      state: {
        email: getValues().email,
      },
    })
  }

  return (
    <>
      <Stack
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="headlineMedium"
          sx={{
            marginTop: '2rem',
            marginBottom: '1rem',
            color: 'primary.500',
          }}
        >
          Welcome to Elpha Secure
        </Typography>
        <Typography
          variant="bodyMedium"
          sx={{
            marginBottom: '2rem',
            color: 'primary.600',
            textAlign: 'center',
          }}
        >
          Login with your information.
        </Typography>
        <form style={{ width: '100%' }} onSubmit={handleSubmit(login)}>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                data-dd-privacy="allow"
                {...field}
                sx={{
                  marginY: '1rem',
                  minWidth: {
                    xs: '100%',
                    sm: '40rem',
                  },
                  width: '100%',
                }}
                label="Email"
                variant="outlined"
                error={!!errors.email}
                helperText={errors.email?.message as string}
              />
            )}
          />
          <br />
          <Controller
            name="password"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                sx={{
                  marginY: '1rem',
                  minWidth: {
                    xs: '100%',
                    sm: '40rem',
                  },
                  width: '100%',
                }}
                label="Password"
                variant="outlined"
                type="password"
                error={!!errors.password}
                helperText={errors.password?.message}
              />
            )}
          />
          <Typography
            variant="bodySmall"
            sx={{
              marginY: '1rem',
              cursor: 'pointer',
              color: 'primary.main',
            }}
            onClick={() => resetPassword()}
          >
            Forgot Password?
          </Typography>
          <br />
          <Button
            type="submit"
            sx={{
              marginY: '1rem',
              minWidth: {
                xs: '100%',
                sm: '40rem',
              },
              width: '100%',
            }}
            variant="contained"
            color="secondary"
          >
            Continue
          </Button>
        </form>
      </Stack>

      <Typography
        variant="bodyMedium"
        sx={{
          marginY: '2rem',
          color: 'primary.500',
        }}
      >
        Don't have a password?
        <Typography
          variant="bodyMedium"
          sx={{
            color: 'primary.main',
            paddingLeft: '1rem',
            cursor: 'pointer',
          }}
          onClick={() => {
            navigate('/login', {
              state: {
                email: getValues().email,
              },
            })
          }}
        >
          Use a one-time link instead
        </Typography>
      </Typography>
    </>
  )
}
