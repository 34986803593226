import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'

const DD_CLIENT_TOKEN = `${window?._env_?.REACT_APP_CLIENT_TOKEN}`

const DD_SITE_DOMAIN = `${window?._env_?.REACT_APP_SITE_DOMAIN}`

const DD_RUM_APP_ID = `${window?._env_?.REACT_APP_RUM_APP_ID}`

const DD_APP_VERSION_NUMBER = `${window?._env_?.REACT_APP_DD_APP_VERSION_NUMBER}`

const DD_SAMPLE_RATE = window?._env_?.REACT_APP_DD_SAMPLE_RATE

/**
 * Initializes telemetry connections with datadog if the correct
 * application credentials have been provided to the workload.
 *
 * We only have these environment credentials set on production,
 * so this will only initialize telemetry on production.
 *
 * @returns {boolean} true if telemetry is initialized, false otherwise
 */
const initializeTelemtry = () => {
  if (
    DD_CLIENT_TOKEN === undefined ||
    DD_SITE_DOMAIN === undefined ||
    DD_RUM_APP_ID === undefined ||
    DD_APP_VERSION_NUMBER === undefined
  ) {
    console.log('No RUM')
    return false
  }

  const DD_SAMPLE_RATE_PARSED = DD_SAMPLE_RATE
    ? parseFloat(DD_SAMPLE_RATE)
    : 100

  datadogLogs.init({
    clientToken: DD_CLIENT_TOKEN,
    site: DD_SITE_DOMAIN,
    forwardErrorsToLogs: true,
    sessionSampleRate: DD_SAMPLE_RATE_PARSED,
  })

  datadogRum.init({
    applicationId: DD_RUM_APP_ID,
    clientToken: DD_CLIENT_TOKEN,
    site: DD_SITE_DOMAIN,
    service: 'authentication-application',
    env: 'production',
    version: DD_APP_VERSION_NUMBER,
    sessionSampleRate: DD_SAMPLE_RATE_PARSED,
    sessionReplaySampleRate: DD_SAMPLE_RATE_PARSED,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
  })

  return true
}

export { initializeTelemtry }
