declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xxl: true
  }
}
const breakpoints = {
  keys: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
  values: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 1024,
    xl: 1280,
    xxl: 1440,
  },
}

export default breakpoints
