import { Stack, Typography, Divider, Box } from '@elphasecure/elpha-design-system'
import { Button } from '@mui/material'
import { useLocation, useNavigate } from 'react-router'

export const CheckEmail = () => {
  const { state } = useLocation()
  let navigate = useNavigate()
  return (
    <>
      <Stack
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="headlineMedium"
          sx={{
            marginTop: '2rem',
            marginBottom: '1rem',
            color: 'primary.500',
          }}
        >
          {state?.title || 'Check your email'}
        </Typography>
        {state?.email ? (
          <Typography
            variant="bodyMedium"
            sx={{
              marginBottom: '2rem',
              color: 'primary.600',
              textAlign: 'center',
            }}
          >
           {state?.description || 'An email has been sent to '} <Box component="span" fontWeight='fontWeightMedium'>{state.email}</Box>
          </Typography>
        ) : (
          <></>
        )}

        <Typography
          variant="bodySmall"
          sx={{
            marginY: '1rem',
          }}
        >
          Can't see the email?{' '}
          <Typography
            variant="bodySmall"
            sx={{
              cursor: 'pointer',
              color: 'primary.main',
            }}
            onClick={() => navigate('/login')}
          >
            Resend
          </Typography>
        </Typography>
        <Button
          type="button"
          sx={{
            marginY: '1rem',
            minWidth: {
              xs: '100%',
              sm: '40rem',
            },
          }}
          variant="outlined"
          color="primary"
          target="_blank"
          href="https://mail.google.com/"
        >
          Open Gmail
        </Button>
        <Button
          type="button"
          sx={{
            marginY: '1rem',
            minWidth: {
              xs: '100%',
              sm: '40rem',
            },
          }}
          variant="outlined"
          color="primary"
          target="_blank"
          href="https://outlook.live.com/mail/0/"
        >
          Open Outlook
        </Button>

        <Divider flexItem>OR </Divider>

        <Typography
          variant="bodyMedium"
          sx={{
            marginY: '1rem',
            cursor: 'pointer',
            color: 'primary.main',
          }}
          onClick={() =>
            navigate('/login-password', {
              state: {
                email: state?.email,
              },
            })
          }
        >
          Login with password
        </Typography>
      </Stack>
    </>
  )
}
