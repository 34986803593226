import {
  ThemedButton as Button,
  Stack,
  TextField,
  Typography,
} from '@elphasecure/elpha-design-system'
import { yupResolver } from '@hookform/resolvers/yup'
import { useStytchB2BClient } from '@stytch/react/b2b'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Yup from 'yup'

export const AccountInfo = () => {
  const stytch = useStytchB2BClient()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      Yup.object({
        firstName: Yup.string().required('Required'),
        lastName: Yup.string().required('Required'),
      })
    ),
    defaultValues: {
      firstName: '',
      lastName: '',
    },
  })

  const onSubmit = (data) => {
    stytch.self
      .update({ name: `${data.firstName} ${data.lastName}` })
      .then(() => {
        window.location.href = searchParams.get('redirect_uri') as string
      })
      .catch((error) => {
        console.error(
          'Error saving user info: ',
          JSON.stringify(error, null, 2)
        )
        toast.error(
          `There was an issue saving your info. Please contact us if the problem persists.`
        )
        navigate({
          pathname: '/authenticate',
        })
      })
  }

  return (
    <Stack
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography
        variant="headlineMedium"
        sx={{
          marginTop: '2rem',
          marginBottom: '1rem',
          color: 'primary.500',
        }}
      >
        Account Info
      </Typography>
      <Typography
        variant="bodyMedium"
        sx={{
          marginBottom: '2rem',
          color: 'primary.600',
        }}
      >
        Hi, Please provide your information to setup your account.
      </Typography>
      <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <Controller
            name="firstName"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                sx={{
                  minWidth: {
                    xs: '100%',
                    sm: '40rem',
                  },
                }}
                label="First Name"
                variant="outlined"
                error={!!errors.firstName}
                helperText={errors.firstName?.message}
              />
            )}
          />
          <Controller
            name="lastName"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                sx={{
                  minWidth: {
                    xs: '100%',
                    sm: '40rem',
                  },
                }}
                label="Last Name"
                variant="outlined"
                error={!!errors.lastName}
                helperText={errors.lastName?.message}
              />
            )}
          />
          <Button
            type="submit"
            sx={{
              minWidth: {
                xs: '100%',
                sm: '40rem',
              },
            }}
            variant="contained"
            color="secondary"
          >
            Continue
          </Button>
        </Stack>
      </form>
    </Stack>
  )
}
