const typography = {
  fontFamily: 'sofia-pro, arial, sans-serif',
  fontFamilySerif: 'Recoleta-Bold, georgia, times, serif',
  htmlFontSize: 10,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  fontSize: 14,
  headlineMedium: {
    fontFamily: 'Recoleta-Bold, georgia, times, serif',
    fontWeight: 600,
    fontSize: '2.8rem',
    letterSpacing: 0,
    lineHeight: 1,
  },
  headlineLarge: {
    fontFamily: 'Recoleta-Bold, georgia, times, serif',
    fontWeight: 600,
    fontSize: '3.6rem',
    letterSpacing: 0,
    lineHeight: 1,
  },
  headlineXLarge: {
    fontFamily: 'Recoleta-Bold, georgia, times, serif',
    fontWeight: 600,
    fontSize: '4.4rem',
    letterSpacing: 0,
    lineHeight: 1,
  },
  headline2XLarge: {
    fontFamily: 'Recoleta-Bold, georgia, times, serif',
    fontWeight: 600,
    fontSize: '5.2rem',
    letterSpacing: 0,
    lineHeight: 1,
  },
  bodyXSmall: {
    fontFamily: 'sofia-pro, arial, sans-serif',
    fontSize: '1.2rem',
    fontWeight: 400,
    letterSpacing: 0,
    lineHeight: 1.5,
  },
  bodySmall: {
    fontFamily: 'sofia-pro, arial, sans-serif',
    fontSize: '1.4rem',
    fontWeight: 400,
    letterSpacing: 0,
    lineHeight: 1.5,
  },
  bodyMedium: {
    fontFamily: 'sofia-pro, arial, sans-serif',
    fontSize: '1.6rem',
    fontWeight: 400,
    letterSpacing: 0,
    lineHeight: 1.5,
  },
  bodyLarge: {
    fontFamily: 'sofia-pro, arial, sans-serif',
    fontSize: '1.8rem',
    fontWeight: 400,
    letterSpacing: 0,
    lineHeight: 1.5,
  },
  bodyXLarge: {
    fontFamily: 'sofia-pro, arial, sans-serif',
    fontSize: '2rem',
    fontWeight: 400,
    letterSpacing: 0,
    lineHeight: 1.5,
  },
  body2XLarge: {
    fontFamily: 'sofia-pro, arial, sans-serif',
    fontSize: '2.8rem',
    fontWeight: 400,
    letterSpacing: 0,
    lineHeight: 1,
  },
}

export default typography
