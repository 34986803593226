import {
  Box,
  Card,
  ElphaLogoSvg,
  Link,
  Stack,
  ToastContainer,
  Typography,
} from '@elphasecure/elpha-design-system'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { Authenticated } from '../components/auth/Authenticated'
import { CheckEmail } from '../components/auth/CheckEmail'
import { Login } from '../components/auth/Login'
import { LoginPassword } from '../components/auth/LoginPassword'
import { Logout } from '../components/auth/Logout'
import { ResetPassword } from '../components/auth/ResetPassword'
import OtpForm from '../components/auth/OneTimePassword'
import { JoinOrg } from '../components/auth/JoinOrg'
import { AccountInfo } from '../components/auth/AccountInfo'
import { PhoneNumber } from '../components/auth/PhoneNumber'
import { RequestPasswordReset } from '../components/auth/RequestPasswordReset'
import ExternalLinkIcon from '../assets/svgs/icon-external-link.svg'

const Layout = () => {
  return (
    <>
      <Stack
        direction="row"
        sx={{
          position: 'absolute',
          top: '0',
          width: '100%',
          height: '64px',
          justifyContent: 'center',
          alignItems: 'center',
          background: '#191933',
        }}
      >
        <Typography variant="bodyXLarge" color="common.white">
          Elpha in the news:
        </Typography>
        &nbsp;
        <Typography variant="bodyXLarge">
          <Link
            href="https://investor.axiscapital.com/press-releases/news-details/2024/AXIS-Expands-Partnership-with-Elpha-Secure---2024-Z_O7iqFmUV/default.aspx"
            target="_blank"
            rel="noopener noreferrer"
            color="secondary.main"
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            AXIS Expands Partnership with Elpha Secure &nbsp;
            <img
              src={ExternalLinkIcon}
              alt="External Link: AXIS Expands Partnership with Elpha Secure"
            />
          </Link>
        </Typography>
      </Stack>
      <Box
        sx={{
          width: {
            xs: '100%',
            sm: '50rem',
            position: 'relative',
          },
        }}
      >
        <Box
          sx={{
            padding: '1rem',
            borderRadius: '50%',
            backgroundColor: 'common.white',
            position: 'absolute',
            top: '-5rem',
            left: {
              xs: 'calc(50vw - 5rem)',
              sm: '20rem',
            },
            zIndex: 1,
          }}
        >
          <ElphaLogoSvg />
        </Box>
        <Card
          sx={{
            width: '100%',
            padding: '5rem 3rem 3rem',
          }}
        >
          <Outlet />

          <Typography
            variant="bodySmall"
            sx={{
              marginTop: '2rem',
              color: 'primary.400',
              textAlign: 'center',
            }}
          >
            Need a log in?{' '}
            <Typography
              variant="bodySmall"
              sx={{
                cursor: 'pointer',
                color: 'primary.main',
              }}
              onClick={() =>
                (window.location.href = 'mailto:help@elphasecure.com')
              }
            >
              Request Access
            </Typography>
          </Typography>
        </Card>
      </Box>
      <ToastContainer
        hideProgressBar
        position="bottom-center"
        closeOnClick
        pauseOnFocusLoss
        pauseOnHover
      />
    </>
  )
}

const Routing = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/login-password" element={<LoginPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/request-password" element={<RequestPasswordReset />} />
        <Route path="/check-email" element={<CheckEmail />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/phone-number" element={<PhoneNumber />} />
        <Route path="/authenticate" element={<Authenticated />} />
        <Route path="/otp" element={<OtpForm />} />
        <Route path="/join-org" element={<JoinOrg />} />
        <Route path="/account-info" element={<AccountInfo />} />
      </Route>
    </Routes>
  )
}

export default Routing
