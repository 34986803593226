import {
  ThemedButton as Button,
  Stack,
  TextField,
  Typography,
} from '@elphasecure/elpha-design-system'
import { useStytchB2BClient } from '@stytch/react/b2b'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router'
import * as Yup from 'yup'
import { createSearchParams, useSearchParams } from 'react-router-dom'
import { phone } from 'phone'
import { useState } from 'react'

export const PhoneNumber = () => {
  let [error, setError] = useState<string>('')
  const navigate = useNavigate()
  const stytch = useStytchB2BClient()
  const [searchParams] = useSearchParams()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      Yup.object({
        phone: Yup.string().required('Required'),
      })
    ),
    defaultValues: {
      phone: '+1-',
    },
  })

  const sendOTP = (data: any) => {
    const parsedPhone = phone(data.phone)
    if (!parsedPhone.isValid) {
      setError('Please enter a valid phone number')
      return
    }
    setError('')
    stytch.otps.sms
      .send({
        organization_id: searchParams.get('organization_id') as string,
        member_id: searchParams.get('member_id') as string,
        mfa_phone_number: parsedPhone.phoneNumber as string,
      })
      .then(() => {
        navigate({
          pathname: '/otp',
          search: createSearchParams({
            organization_id: searchParams.get('organization_id') as string,
            member_id: searchParams.get('member_id') as string,
          }).toString(),
        })
      })
      .catch((error) => {
        setError(error.error_message ?? error.message)
      })
  }

  return (
    <>
      <Stack
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="headlineMedium"
          sx={{
            marginTop: '2rem',
            marginBottom: '1rem',
            color: 'primary.500',
          }}
        >
          Phone number
        </Typography>
        <Typography
          variant="bodyMedium"
          sx={{
            marginBottom: '2rem',
            color: 'primary.600',
            textAlign: 'center'
          }}
        >
          Please provide your phone number for two factor authentication
        </Typography>
        <form style={{ width: '100%' }} onSubmit={handleSubmit(sendOTP)}>
          <Stack spacing={2}>
            <Controller
              name="phone"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  sx={{
                    minWidth: {
                      xs: '100%',
                      sm: '40rem',
                    },
                  }}
                  label="Phone number"
                  variant="outlined"
                  error={!!errors.phone}
                  helperText={errors.phone?.message}
                />
              )}
            />
            <Button
              type="submit"
              sx={{
                minWidth: {
                  xs: '100%',
                  sm: '40rem',
                },
              }}
              variant="contained"
              color="secondary"
            >
              Send OTP
            </Button>

            {error && (
              <Typography
                variant="bodySmall"
                sx={{ color: 'error.main', textAlign: 'center' }}
              >
                {error}
              </Typography>
            )}
          </Stack>
        </form>
      </Stack>
    </>
  )
}
