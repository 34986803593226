import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import Routing from './routes/routes'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import './styles/index.css'
import theme from './theme/theme'
import { StytchB2BProvider } from '@stytch/react/b2b'
import { StytchB2BHeadlessClient } from '@stytch/vanilla-js/b2b/headless'
import { datadogRum } from '@datadog/browser-rum'
import { initializeTelemtry } from './utilities/telemetry'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

const REACT_APP_STYTCH_PUBLIC_TOKEN = `${
  window?._env_?.REACT_APP_STYTCH_PUBLIC_TOKEN !== undefined
    ? window?._env_?.REACT_APP_STYTCH_PUBLIC_TOKEN
    : process?.env?.REACT_APP_STYTCH_PUBLIC_TOKEN
}`

if (initializeTelemtry() === true) {
  datadogRum.startSessionReplayRecording()
}

const getCookieDomain = () => {
  if (window.location.host.includes('localhost')) return window.location.host
  const domain = `.elphasecure.${window.location.host.split('elphasecure.')[1]}`
  return domain
}

const stytch = new StytchB2BHeadlessClient(REACT_APP_STYTCH_PUBLIC_TOKEN, {
  cookieOptions: {
    availableToSubdomains: true,
    domain: getCookieDomain(),
  },
})

ReactDOM.render(
  <React.StrictMode>
    <StytchB2BProvider stytch={stytch}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <CssBaseline />
            <Routing />
          </BrowserRouter>
        </ThemeProvider>
      </QueryClientProvider>
    </StytchB2BProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
