import { datadogRum } from '@datadog/browser-rum'
import { Loading, Typography } from '@elphasecure/elpha-design-system'
import { useStytchB2BClient, useStytchMemberSession } from '@stytch/react/b2b'
import { B2BAuthenticateResponseWithMFA } from '@stytch/vanilla-js/b2b'
import { useCallback, useEffect, useState } from 'react'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useGetEnvKey } from '../../api/useGetEnv'
import useUserNavigationMFA from '../../hooks/useUserNavigationMFA'

const env = `${process?.env?.REACT_APP_ENV || ''}`

export const Authenticated = () => {
  const [error, setError] = useState<string | null>(null)
  const stytch = useStytchB2BClient()
  const { session } = useStytchMemberSession()
  const { data, error: apiError } = useGetEnvKey(
    (session?.custom_claims?.organization_data as any)?.environment_key
  )
  const navigate = useNavigate()
  const [setAuthResponse] = useUserNavigationMFA()

  const authenticate = async () => {
    const token = new URLSearchParams(window.location.search).get('token') ?? ''
    const tokenType =
      new URLSearchParams(window.location.search).get('stytch_token_type') ?? ''

    try {
      if (tokenType === 'discovery') {
        let authResponse: B2BAuthenticateResponseWithMFA
        const { discovered_organizations } =
          await stytch.magicLinks.discovery.authenticate({
            discovery_magic_links_token: token,
          })

        /**
         * If the user is associated with more than one environment, we need to
         * ensure they contact us to fix their misconfigured account. This is
         * an error and should not allow them to login.
         */
        if (discovered_organizations.length !== 1) {
          throw new Error(
            "Your account is misconfigured and we're unable to authenticate your account. Please contact us."
          )
        }

        if (
          discovered_organizations[0].organization.trusted_metadata
            .active_status === false
        ) {
          throw new Error('Your account is not active. Please contact us.')
        }

        authResponse = await stytch.discovery.intermediateSessions.exchange({
          organization_id:
            discovered_organizations[0].organization.organization_id,
          session_duration_minutes: 960,
        })
        setAuthResponse(authResponse)
      } else if (
        tokenType === 'multi_tenant_magic_links' ||
        tokenType === 'magic_links_token'
      ) {
        let authResponse: B2BAuthenticateResponseWithMFA
        authResponse = await stytch.magicLinks.authenticate({
          magic_links_token: token,
          session_duration_minutes: 960,
        })
        setAuthResponse(authResponse)
      } else if (tokenType === 'multi_tenant_passwords') {
        navigate({
          pathname: '/reset-password',
          search: createSearchParams({
            token,
          }).toString(),
        })
      }
    } catch (error: any) {
      console.error(
        'Error authenticating user: ',
        JSON.stringify(error, null, 2)
      )
      if (error?.error_message || error?.message) {
        toast.error(error.error_message || error.message)
      }
      navigate('/logout')
    }
  }

  const navigateUser = useCallback(
    (redirectUri: string) => {
      const memberDetails = stytch.self.getSync()
      if (memberDetails?.name && memberDetails?.name?.trim()) {
        datadogRum.setUser({
          id: memberDetails?.member_id,
          name: memberDetails?.name,
          email: memberDetails?.email_address,
        })
        datadogRum.addAction('Successful user authentication', {
          id: memberDetails?.member_id,
          mfa_enrolled: memberDetails?.mfa_enrolled,
        })
        window.location.href = redirectUri
      } else {
        navigate({
          pathname: '/account-info',
          search: createSearchParams({
            redirect_uri: redirectUri,
          }).toString(),
        })
      }
    },
    [navigate, stytch]
  )

  useEffect(() => {
    authenticate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const token = new URLSearchParams(window.location.search).get('token') ?? ''

    if (!session && !token) {
      navigate('/login')
    }
  }, [stytch, navigate, session])

  useEffect(() => {
    if (env === 'local' && session) {
      navigateUser('http://localhost:3000')
      return
    }

    if (!data) {
      return
    }
    //  The API returns a JSON object with the following structure:
    // {"id": "XYZ-123", "name": "internal-beta", "domain": "internal-beta.dev.elphasecure.io"}
    // The domain is used to redirect the user to the correct app.
    const domain = data?.data.domain

    /**
     * In the future, if we want to add more application locations for different roles, the routing
     * for that application will happen here. e.g.
     *
     *   const adminURL = `${window.location.protocol}//admin.${domain}`;
     *   if ((session?.custom_claims?.elphasecure as any)?.roles?.admin) {
     *    window.location.href = adminURL;
     *   }
     */
    const clientURL = `${window.location.protocol}//app.${domain}`
    const brokerURL = `${window.location.protocol}//broker.${domain}`

    if ((session?.custom_claims?.elphasecure as any)?.roles?.client) {
      navigateUser(clientURL)
    } else if ((session?.custom_claims?.elphasecure as any)?.roles?.broker) {
      navigateUser(brokerURL)
    } else {
      setError(
        'Your account is not associated with any environment. Please contact us.'
      )
      datadogRum.setUser({ id: session?.member_id })
      datadogRum.addError('User is not associated with any environment')
    }
  }, [data, session, navigateUser])

  return (
    <div>
      {apiError ? (
        <>
          <Typography variant="bodyMedium" component="div">
            An error occurred redirecting to your environment. Try refreshing
            the browser and contact us if the problem persists.
          </Typography>
          <Typography
            variant="bodyMedium"
            sx={{
              cursor: 'pointer',
              color: 'primary.main',
              textAlign: 'center',
              width: '100%',
              marginTop: '1.5rem',
            }}
            component="div"
            onClick={() => navigate('/logout')}
          >
            Go Back To Login
          </Typography>
        </>
      ) : error ? (
        <>
          <Typography variant="bodyMedium" component="div">
            {error}
          </Typography>

          <Typography
            variant="bodyMedium"
            sx={{
              cursor: 'pointer',
              color: 'primary.main',
              textAlign: 'center',
              width: '100%',
              marginTop: '1.5rem',
            }}
            component="div"
            onClick={() => navigate('/logout')}
          >
            Go Back To Login
          </Typography>
        </>
      ) : (
        <Loading fullScreen />
      )}
    </div>
  )
}
